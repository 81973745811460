import AboutText from "./AboutText";

const About = () => {
    return (
        <div>
            <AboutText />
        </div>
    )
}

export default About